@import "normalize.css";

$color: (
	"accent": #25ce95,
	"mark": #172c35,
	"bg": #ffffff,
);

@font-face {
	font-family: "Space Grotesk";
	font-weight: 700;
	font-display: swap;
	src: url("../../fonts/space-grotesk/SpaceGrotesk-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Space Grotesk";
	font-weight: 600;
	font-display: swap;
	src: url("../../fonts/space-grotesk/SpaceGrotesk-SemiBold.ttf")
		format("truetype");
}

@font-face {
	font-family: "Space Grotesk";
	font-weight: 500;
	font-display: swap;
	src: url("../../fonts/space-grotesk/SpaceGrotesk-Medium.ttf")
		format("truetype");
}

@font-face {
	font-family: "Space Grotesk";
	font-weight: 400;
	font-display: swap;
	src: url("../../fonts/space-grotesk/SpaceGrotesk-Regular.ttf")
		format("truetype");
}

@font-face {
	font-family: "Space Grotesk";
	font-weight: 300;
	font-display: swap;
	src: url("../../fonts/space-grotesk/SpaceGrotesk-Light.ttf")
		format("truetype");
}

html {
	background-color: lighten(map-get($color, "accent"), 50%);
	font-family: "Space Grotesk", sans-serif;
	font-size: 16px;
	font-weight: 500;

	body {
		font-family: "Space Grotesk", sans-serif;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
		}
	}

	p {
		line-height: 1.375em;
	}

	mark {
		background: linear-gradient(
			to bottom,
			transparent 65%,
			rgba(map-get($color, "accent"), 0.5) 50%
		);
	}
}

header.primary {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 4rem;
	font-size: 1rem;

	nav {
		font-size: 1.25rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;

		a:not(.button-style) {
			color: map-get($color, "mark");
			text-decoration: none;
		}
	}

	@media (max-width: 640px) {
		flex-direction: column;
	}
}

button,
.button-style {
	display: block;
	text-decoration: none;
	font-weight: 700;
	font-size: 1rem;
	appearance: none;
	background-color: map-get($color, "accent");
	color: map-get($color, "bg");
	border: none;
	padding: 0.5em 1em;
	border-radius: 0 0.5em 0 0.5em;
	outline: 0.06725em solid map-get($color, "accent");
	outline-offset: -1px;
	box-shadow: 0 0.5em 1.5em rgba(map-get($color, "accent"), 0.5);
	transition: 0.25s ease background-color, 0.25s ease box-shadow,
		0.25s ease outline;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			outline: 0.25em solid darken(map-get($color, "accent"), 2.5%);
			background-color: darken(map-get($color, "accent"), 2.5%);
			box-shadow: 0 0.5em 1.5em rgba(map-get($color, "accent"), 0.75);
		}
	}
}

#page-content {
	position: relative;
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 4rem;
	padding: 4rem;
	overflow: hidden;
	box-sizing: border-box;
	max-width: 1440px;
	margin: 0 auto;

	@media (max-width: 768px) {
		padding: 2rem;
	}

	&.project {
		overflow: visible;
	}

	&::before {
		content: "";
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-size: cover;
		background-attachment: fixed;
		background-blend-mode: multiply;
	}

	&.home {
		overflow: hidden;

		.splash {
			z-index: 2;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			gap: 1.5rem;
			padding: 0;
			border-radius: 1rem;
			flex-grow: 1;

			p {
				margin: 0;

				&.big {
					line-height: 1.25em;
					font-size: 4ch;
					font-weight: 700;

					@media (max-width: 768px) {
						font-size: 1.5rem;
					}
				}

				&:not(.big) {
					line-height: 1.5em;
				}
			}

			> * {
				margin: 0;
			}

			img.joe-avatar {
				display: block;
				width: 8rem;
				border-radius: 100%;
				overflow: hidden;
				outline: 1rem solid white;
				align-self: center;
			}
		}
	}
}

.clients-grid {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 2rem;

	a {
		img {
			display: block;
			height: 1.5rem;
		}

		@media (hover: hover) {
			&:not(:hover) {
				filter: grayscale(100%);
				mix-blend-mode: multiply;
			}
		}
	}
}

#page-content {
	.animate-me {
		display: inline;
		position: relative;
		transition: 2s ease opacity, 0.5s ease transform;

		&:not(.animate-in) {
			opacity: 0;
			transform: translate(0, -2rem);
			pointer-events: none;
		}

		&.animate-in {
			opacity: 1;
			transform: translate(0, 0);
		}
	}
}

.projects-grid {
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(1, auto);
	grid-auto-flow: dense;

	* {
		display: block;
		width: 100%;
		height: auto;
		max-width: 100%;
	}
}

.project {
	.react-pdf__Document,
	.react-pdf__Page {
		min-height: unset !important;
		min-width: unset !important;

		.textLayer,
		.annotationLayer {
			// display: none;
		}
	}

	.react-pdf__Document {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	canvas {
		width: 100% !important;
		height: auto !important;
	}

	&.summary {
		display: flex;
		flex-direction: column;
		background-color: map-get($color, "bg");
		box-shadow: 0 1rem 2rem rgba(map-get($color, "accent"), 12.5%);
		cursor: pointer;

		.project-image {
			height: 192px;
			overflow: hidden;

			> * {
				transform: rotate(-30deg) scale(2);
			}
		}

		.project-info {
			padding: 1rem;
		}
	}

	&.detail-view {
		display: grid;
		gap: 1rem;
		align-items: start;
		grid-template-columns: 300px 1fr;
		grid-template-rows: repeat(1, auto);

		header {
			position: relative;
			height: 100%;

			.project-info {
				background-color: map-get($color, "bg");
				position: sticky;
				top: 1rem;
				padding: 2rem;
			}
		}

		main {
			overflow: hidden;

			.project-image {
				overflow: hidden;
			}
		}
	}

	.project-info {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		flex-grow: 1;

		> * {
			margin: 0;
		}
	}
}

ul.tags {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 0.75rem;

	li.tag {
		margin: 0;
		border-radius: 0.25em;
		width: auto;
		color: grey;

		&::before {
			content: "#";
			color: map-get($color, "accent");
			margin-right: 0.25em;
		}
	}
}
