@import "normalize.css";
@font-face {
  font-family: "Space Grotesk";
  font-weight: 700;
  font-display: swap;
  src: url("../../fonts/space-grotesk/SpaceGrotesk-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Space Grotesk";
  font-weight: 600;
  font-display: swap;
  src: url("../../fonts/space-grotesk/SpaceGrotesk-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Space Grotesk";
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/space-grotesk/SpaceGrotesk-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Space Grotesk";
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/space-grotesk/SpaceGrotesk-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Space Grotesk";
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/space-grotesk/SpaceGrotesk-Light.ttf") format("truetype");
}
html {
  background-color: #f5fdfa;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
html body {
  font-family: "Space Grotesk", sans-serif;
}
html p {
  line-height: 1.375em;
}
html mark {
  background: linear-gradient(to bottom, transparent 65%, rgba(37, 206, 149, 0.5) 50%);
}

header.primary {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  font-size: 1rem;
}
header.primary nav {
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
header.primary nav a:not(.button-style) {
  color: #172c35;
  text-decoration: none;
}
@media (max-width: 640px) {
  header.primary {
    flex-direction: column;
  }
}

button,
.button-style {
  display: block;
  text-decoration: none;
  font-weight: 700;
  font-size: 1rem;
  appearance: none;
  background-color: #25ce95;
  color: #ffffff;
  border: none;
  padding: 0.5em 1em;
  border-radius: 0 0.5em 0 0.5em;
  outline: 0.06725em solid #25ce95;
  outline-offset: -1px;
  box-shadow: 0 0.5em 1.5em rgba(37, 206, 149, 0.5);
  transition: 0.25s ease background-color, 0.25s ease box-shadow, 0.25s ease outline;
  cursor: pointer;
}
@media (hover: hover) {
  button:hover,
.button-style:hover {
    outline: 0.25em solid #23c38d;
    background-color: #23c38d;
    box-shadow: 0 0.5em 1.5em rgba(37, 206, 149, 0.75);
  }
}

#page-content {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4rem;
  padding: 4rem;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 1440px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  #page-content {
    padding: 2rem;
  }
}
#page-content.project {
  overflow: visible;
}
#page-content::before {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-attachment: fixed;
  background-blend-mode: multiply;
}
#page-content.home {
  overflow: hidden;
}
#page-content.home .splash {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  padding: 0;
  border-radius: 1rem;
  flex-grow: 1;
}
#page-content.home .splash p {
  margin: 0;
}
#page-content.home .splash p.big {
  line-height: 1.25em;
  font-size: 4ch;
  font-weight: 700;
}
@media (max-width: 768px) {
  #page-content.home .splash p.big {
    font-size: 1.5rem;
  }
}
#page-content.home .splash p:not(.big) {
  line-height: 1.5em;
}
#page-content.home .splash > * {
  margin: 0;
}
#page-content.home .splash img.joe-avatar {
  display: block;
  width: 8rem;
  border-radius: 100%;
  overflow: hidden;
  outline: 1rem solid white;
  align-self: center;
}

.clients-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}
.clients-grid a img {
  display: block;
  height: 1.5rem;
}
@media (hover: hover) {
  .clients-grid a:not(:hover) {
    filter: grayscale(100%);
    mix-blend-mode: multiply;
  }
}

#page-content .animate-me {
  display: inline;
  position: relative;
  transition: 2s ease opacity, 0.5s ease transform;
}
#page-content .animate-me:not(.animate-in) {
  opacity: 0;
  transform: translate(0, -2rem);
  pointer-events: none;
}
#page-content .animate-me.animate-in {
  opacity: 1;
  transform: translate(0, 0);
}

.projects-grid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-auto-flow: dense;
}
.projects-grid * {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.project .react-pdf__Document,
.project .react-pdf__Page {
  min-height: unset !important;
  min-width: unset !important;
}
.project .react-pdf__Document {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.project canvas {
  width: 100% !important;
  height: auto !important;
}
.project.summary {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 1rem 2rem rgba(37, 206, 149, 0.125);
  cursor: pointer;
}
.project.summary .project-image {
  height: 192px;
  overflow: hidden;
}
.project.summary .project-image > * {
  transform: rotate(-30deg) scale(2);
}
.project.summary .project-info {
  padding: 1rem;
}
.project.detail-view {
  display: grid;
  gap: 1rem;
  align-items: start;
  grid-template-columns: 300px 1fr;
  grid-template-rows: repeat(1, auto);
}
.project.detail-view header {
  position: relative;
  height: 100%;
}
.project.detail-view header .project-info {
  background-color: #ffffff;
  position: sticky;
  top: 1rem;
  padding: 2rem;
}
.project.detail-view main {
  overflow: hidden;
}
.project.detail-view main .project-image {
  overflow: hidden;
}
.project .project-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
}
.project .project-info > * {
  margin: 0;
}

ul.tags {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
}
ul.tags li.tag {
  margin: 0;
  border-radius: 0.25em;
  width: auto;
  color: grey;
}
ul.tags li.tag::before {
  content: "#";
  color: #25ce95;
  margin-right: 0.25em;
}

